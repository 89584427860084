import Flickity from 'flickity';

export default function flickityCarousels() {

	var carouselContent = document.querySelectorAll('.carousel-content');

	for (var i = 0; i < carouselContent.length; i++) {

		var carousel = carouselContent[i];

		var flkty = new Flickity(carousel, {

			setGallerySize: false,
			percentPosition: false,
			dragThreshold: 10,
			freeScroll: false,
			selectedAttraction: 0.15,
			friction: 0.5,
			prevNextButtons: false,
			pageDots: true,
			cellAlign: 'left',
			contain: true

		});

		flkty.on('dragStart', function() {

			carousel.classList.add('is-dragging');

		});

		flkty.on('dragEnd', function() {

			carousel.classList.remove('is-dragging');

		});

	}

}
